.basket-container {
    max-width: 600px;
    margin: 0 auto;
}

.user-basket {
    margin-top: 6px;
}

.basket-items {
    margin-bottom: 20px;
}

.basket-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.basket-item img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.totalHeading {
    font-size: 15px;
    font-family: serif;
}

.priceBasket {
    margin-top: 24.8px;
}

.basket-summary {
    padding: 10px;
    border: 1px solid #ccc;
}

.basket-summary p {
    margin: 5px 0;
}

.order-button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 10px;
}
