.product {
    padding: 3px;
}

.modal-z {
    z-index: 99999;
}

.custom-card {
    padding: 16px;
    height: 100%;
    justify-content: space-between;
    gap: 10px;
}

.product-img {
    border-radius: 6px;
    max-width: 263px;
    max-height: 253px;
    border: 1px solid #efefef;
    padding: 2px;
    height: 100%;
}

.info {
    height: 100%;
    padding-bottom: 0.375rem;

}

.product-title {
    font-size: 14px;
    font-weight: 500;
    padding: 6px 0;
    font-family: Montserrat, Arial, sans-serif
}

.product-info {
    opacity: 0.8;
    line-height: 17px;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.priceBuy {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    margin-top: 4px;
}

.product-price {
    display: flex;
    align-items: center;
    column-gap: 1px;
    font-size: 15px;
}

.buy:hover {
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}

.custom-price {
    font-size: 18px;
    font-weight: 600;
}

.like-basket {
    display: flex;
    column-gap: 6px;
}

.like {
    border-radius: 6px;
    background-color: transparent;
    opacity: 0.9;
    transition: all .2s ease-in-out;
    text-align: center;
    border: 1px solid rgb(176, 176, 176);
    padding: 6px 7px;
}

.like svg {
    width: 18px;
    height: 18px;
}

.like svg rect {
    opacity: 0;
}

.toBasket {
    border-radius: 6px;
    background-color: #EC6323;
    transition: all .2s ease-in-out;
    text-align: center;
    flex-grow: 1;
}

.toBasket:hover {
    transition: all .3s ease-in-out;
    opacity: 0.8;
}

.toBasket svg {
    width: 18px;
    height: 18px;
}

br {
    display: none;
}

@media (max-width: 768px) {
    .product-img {
        max-width: 236px;
    }

    .custom-card {
        padding: 8px;
    }
}

@media (max-width: 552px) {
    .custom-card {
        padding: 5px;
    }

    .product-img {
        max-width: 200px;
        width: 200px;
        /* min-width: 180px; */
        /* max-height: 190px; */
    }

    .product-title {
        font-size: 13px;
        padding: 4px 0;
    }

    .priceBuy {
        margin-top: 1px;
        row-gap: 5px;
    }

    .custom-price {
        font-size: 14px;
    }

    .byOne {
        justify-content: center !important;
    }
}